<template>
  <div>
    <div v-if="$apollo.loading">Loading...</div>
    <div v-else>
      <b-card-group deck>
        <div class="" style="padding-bottom: 1.2rem" v-for="item in this.latestFarmPictures.edges" :key="item.node.id">
          <b-card no-body class="card h-100" style="width: 16rem; margin-right: 0.6rem; margin-left: 0.6rem">
              <a :href="`#/farmscamerapicturespage?camera_key=${item.node.cameraKey}`">
                <b-img :src="`/${item.node.path}`" alt="" class="card-img-top"></b-img>
              </a>
            <b-card-body style="padding: 0.6rem">
              <h5 class="card-title">{{item.node.cameraName}}</h5>
            </b-card-body>
            <b-card-footer>
              <p class="card-text text-center">{{item.node.uploadDatetime}}</p>
            </b-card-footer>
          </b-card>
        </div>
      </b-card-group>
    </div>
  </div>
</template>

<script>
import { LATEST_FARM_PICTURES_QUERY } from "../../components/camera-picture-query";
import { nullCheckConvert } from "../../components/null-check";

export default {
  components: {
  },
  data() {
    return {
    };
  },
  created() {
    this.refetch();
  },
  apollo: {
    latestFarmPictures: {
      query: LATEST_FARM_PICTURES_QUERY,
      variables: {},
      result({ data, loading }) {
        console.log(data);
      },
      error(error) {
        console.error("latestFarmPictures", error);
      }
    }
  },
  methods: {
    refetch: function() {
      this.$apollo.queries.latestFarmPictures.refetch();
    },
  }
};
</script>
