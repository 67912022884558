<template>
  <div class="">
    <b-card>
      <b-card-body>
        <div>
          <div class="input-group btn-info">
            <input type="text" class="form-control" placeholder="検索条件" />
            <span class="input-group-btn">
              <button type="button" class="btn btn-primary">検索</button>
            </span>
          </div>
          <b>※現在は使用できません。</b>
        </div>
      </b-card-body>
    </b-card>
    <camera-picture-list />
  </div>
</template>

<script>
import CameraPictureList from "../components/CameraPictureList";

const DEFAULT_INDEX = 0;

export default {
  components: {
    CameraPictureList
  },
  data() {
    return {
    };
  }
};
</script>
